import React from "react";
import { Button, Box } from "@mui/material";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ContractContext } from "../assets/abi/farm-contract";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import walletConnectLogo from "../assets/img/walletconnect.png";
import metamaskLogo from "../assets/img/metamask.png";
import trustwalletLogo from "../assets/img/trustwallet.png";

interface ConnectButtonProps {
  style?: React.CSSProperties;
  nickname?: string;
}

class ConnectButton extends React.Component<ConnectButtonProps> {
  state = {
    WalletConnectSelectionDlgOpen: false,
    connected: false,
  };
  baseState: any;
  nickname?: string;
  constructor(props: ConnectButtonProps) {
    super(props);
    this.baseState = this.state;

    this.nickname = props.nickname;
  }

  componentDidMount = () => {
    //this.handleConnectWallet();
    //this.handleConnectWallet_WalletConnect();

    try {
      let existingConnectionJSON = localStorage.getItem("walletconnect");
      // walletconnect
      if (existingConnectionJSON) {
        let existingConnection = false;
        try {
          let conn = JSON.parse(existingConnectionJSON);
          existingConnection = conn.connected;
        } catch {
          existingConnection = false;
        }
        if (existingConnection) {
          this.initialConnect_WalletConnect(); // reuse created connection. no Modal will show
        }
      } else {
        if (window.ethereum) {
          this.initialConnect(); // trust/metamask
        }
      }
    } catch {}
  };

  componentWillUnmount = () => {};

  componentWillReceiveProps(props: ConnectButtonProps) {
    this.nickname = props.nickname;
  }

  initialConnect_WalletConnect = async () => {
    //  Create WalletConnect Provider
    window.walletConnectProvider = new WalletConnectProvider({
      infuraId: "5f6251b55dc4409e9213b9741b8bedba", // Required
      chainId: 56, //
      rpc: {
        56:
         // "https://polygon-mumbai.infura.io/v3/5f6251b55dc4409e9213b9741b8bedba", // bsc testnet https://data-seed-prebsc-1-s1.binance.org:8545
         "https://bsc-dataseed.binance.org/" //  https://rpc.tenderly.co/fork/5a7a6b30-0e2b-4fc7-b3e9-e751c4126be5
      } /*,
      qrcodeModalOptions: {
        mobileLinks: [
          "encrypted ink"
        ],
        desktopLinks: [
          "encrypted ink"
        ]
      }*/,
    });
    //  Enable session (triggers QR Code modal)
    try {
      await window.walletConnectProvider.enable();

      window.web3 = new Web3(window.walletConnectProvider as any);

      const accounts = await window.web3.eth.getAccounts();
      if (accounts.length > 0) {
        window.connected = true;
        this.setState({ connected: true });
        window.walletAddress = accounts[0];
        this.handleConnectWallet_WalletConnect();
      }
    } catch {
      console.log("error on walletconnect");
    }
  };

  initialConnect = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res: string[]) => {
          if (res.length >= 1) {
            window.connected = true;
            this.setState({ connected: true });
            window.walletAddress = res[0];
            this.handleConnectWallet();
          }
        });
    } else if (
      window.confirm(
        "You have to use MetaMask or TrustWallet to connect. Press OK to continue to the MetaMask download page. https://metamask.io/download"
      )
    ) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = "https://metamask.io/download";
      a.click();
    }
  };

  handleConnectWallet = () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);

      window.ethereum.request({ method: "eth_accounts" }).then((res: any) => {
        if (res.length >= 1) {
          window.connected = true;
          this.setState({ connected: true });
          window.walletAddress = res[0];
          this.updateRefLink();
          this.forceUpdate();

          window.farm_contract = new window.web3.eth.Contract(
            window.contract_abi,
            window.contractAddress
          ) as unknown as ContractContext;
        }
      });
    }
  };

  handleConnectWallet_WalletConnect = async () => {
    if (window.walletConnectProvider) {
      //window.web3 = new Web3(window.walletConnectProvider);

      const accounts = await window.web3.eth.getAccounts();
      if (accounts.length > 0) {
        window.connected = true;
        this.setState({ connected: true });
        window.walletAddress = accounts[0];
        this.updateRefLink();
        this.forceUpdate();

        window.farm_contract = new window.web3.eth.Contract(
          window.contract_abi,
          window.contractAddress
        ) as unknown as ContractContext;
      }
    }
  };

  handleDeactivateAccount = () => {
    window.connected = false;
    window.walletAddress = "";
    this.setState({ connected: false });
    this.forceUpdate();
  };

  handleDeactivateAccount_WalletConnect = () => {
    window.walletConnectProvider.disconnect();
    window.connected = false;
    window.walletAddress = "";
    this.setState({ connected: false });
    this.forceUpdate();
  };

  updateRefLink = () => {
    let refLinkWalledId = document.getElementById("walletAddressRefLink");
    if (refLinkWalledId != null) {
      refLinkWalledId.innerText = window.walletAddress;
    }
  };

  handleWalletConnectDlgSelection = (selection: string) => {
    this.setState({
      WalletConnectSelectionDlgOpen: false,
    });

    switch (selection) {
      case "metamask":
      case "trustwallet":
        this.initialConnect();
        break;

      case "walletconnect":
        this.initialConnect_WalletConnect();
        break;
    }
  };

  handleWalletConnectDlgClose = () => {
    this.setState({
      WalletConnectSelectionDlgOpen: false,
    });
  };

  onClick_connect = () => {
    this.setState({
      WalletConnectSelectionDlgOpen: true,
    });

    //initialConnect_WalletConnect
  };

  onClick_disconnect = () => {
    try {
      this.handleDeactivateAccount_WalletConnect();
    } catch {}

    try {
      this.handleDeactivateAccount();
    } catch {}
  };

  render = () => {
    let button;
    if (this.state.connected) {
      button = (
        <Button
          fullWidth={true}
          variant={"contained"}
          color={"primary"}
          sx={{ my: 1, marginRight: 1 }}
          size="small"
          onClick={this.onClick_disconnect}
        >
          <PowerOffIcon
            style={{ height: "20px", marginRight: "8px" }}
          ></PowerOffIcon>
          disconnect
        </Button>
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      button = (
        <Button
          fullWidth={true}
          variant={"contained"}
          color={"primary"}
          sx={{ my: 1, marginRight: 1 }}
          size="small"
          onClick={this.onClick_connect}
        >
          <PowerIcon style={{ height: "20px", marginRight: "8px" }}></PowerIcon>
          connect
        </Button>
      );
    }

    let walletConnectPreSelectDlg = (
      <Dialog
        onClose={this.handleWalletConnectDlgClose}
        open={this.state.WalletConnectSelectionDlgOpen}
      >
        <List sx={{ p: "32px" }}>
          <ListItem
            autoFocus
            button
            onClick={() => this.handleWalletConnectDlgSelection("metamask")}
          >
            <ListItemAvatar>
              <img src={metamaskLogo} style={{ width: "40px" }} alt="wallet" />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: "24pt" }}
              primary="Metamask"
            />
          </ListItem>
          <ListItem
            autoFocus
            button
            onClick={() => this.handleWalletConnectDlgSelection("trustwallet")}
          >
            <ListItemAvatar>
              <img
                src={trustwalletLogo}
                style={{ width: "40px" }}
                alt="wallet"
              />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: "24pt" }}
              primary="Trust Wallet"
            />
          </ListItem>
          <ListItem
            autoFocus
            button
            onClick={() =>
              this.handleWalletConnectDlgSelection("walletconnect")
            }
          >
            <ListItemAvatar>
              <img
                src={walletConnectLogo}
                style={{ width: "40px" }}
                alt="wallet"
              />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: "24pt" }}
              primary="WalletConnect"
            />
          </ListItem>
        </List>
      </Dialog>
    );

    return (
      <Box>
        <div>
          {/* {this.state.connected && window.walletAddress &&
            `${window.walletAddress.slice(0, 6)}...${window.walletAddress.slice(
              window.walletAddress.length - 4,
              window.walletAddress.length
            )}`}
          {this.nickname && ` (${this.nickname})`} */}
        </div>
        <div style={{ padding: "0 0 0 0" }}>
          {this.state.connected ? (
            <Button
              variant="contained"
              disableRipple
              sx={{
                // mt: 4,
                py: "8px",
                px: "23px",
                backgroundColor: "#F8A21B",
                borderRadius: "55px",
                textTransform: "none",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                color: "#24203B",
                "&:hover": {
                  backgroundColor: "rgb(36 32 59)",
                  color: "white",
                },
                "@media (max-width:767px)": {
                  fontSize: "14px",
                },
              }}
              onClick={this.onClick_disconnect}
            >
              {this.state.connected &&
                window.walletAddress &&
                `${window.walletAddress.slice(
                  0,
                  6
                )}...${window.walletAddress.slice(
                  window.walletAddress.length - 4,
                  window.walletAddress.length
                )}`}
            </Button>
          ) : (
            <Button
              variant="contained"
              disableRipple
              sx={{
                // mt: 4,
                py: "8px",
                px: "23px",
                backgroundColor: "#F8A21B",
                borderRadius: "55px",
                textTransform: "none",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                color: "#24203B",
                "&:hover": {
                  backgroundColor: "rgb(36 32 59)",
                  color: "white",
                },
                "@media (max-width:767px)": {
                  fontSize: "14px",
                },
              }}
              onClick={this.onClick_connect}
            >
              Connect
            </Button>
          )}
        </div>
        {walletConnectPreSelectDlg}
      </Box>
    );
  };
}

export default ConnectButton;
