import React from "react";
import {
  Box,
  Button,
  Divider,
  Stack,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import appLogo from "../assets/img/allstars.png";
import Banner from "../assets/img/dappside-banner.png";
const pages = [
  { name: "Home", url: "/" },
  { name: "Telegram", url: "https://t.me/BNB_AllStars" },
  { name: "WhitePaper", url: "/whitepaper" },
  { name: "Audit", url: "https://www.cryptoprim.com/audit/27" },
  {
    name: "Contract",
    url: "https://bscscan.com/address/0x229EB38D9F66ed6E5AefC89c57Ceea8DdA07aE25#code",
  },
];
interface HeaderProps {
  style?: React.CSSProperties;
}
interface HeaderState {
  innerWidth: number;
  innerHeight: number;
  isOpen: boolean;
  menuEl: null | HTMLElement;
}
class Header extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    isOpen: false,
    menuEl: null,
  };
  resize = () => {
    let width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    let height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.setState({
      innerWidth: width,
      innerHeight: height,
    });
  };
  componentDidMount = () => {
    window.addEventListener("resize", this.resize);
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resize);
  };
  handleCloseNavMenu = () => {
    this.setState({
      menuEl: null,
    });
  };
  handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event);
    this.setState({
      menuEl: event.currentTarget,
    });
  };
  render = () => {
    const isTablet = this.state.innerWidth < 1024 ? true : false;
    return (
      <Toolbar
        sx={{
          pt: 4,
          "@media (max-width:1024px)": {
            px: 0,
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            background: "rgba(255, 255, 255, 0.05)",
            backdropFilter: "blur(9px)",
            borderRadius: "7px",
            py: "15px",
            px: "30px",
          }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="small"
                aria-controls={
                  Boolean(this.state.menuEl) ? "basic-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={Boolean(this.state.menuEl) ? "true" : undefined}
                onClick={this.handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <div style={{ display:"flex", gap:"10px", width:"100%", justifyContent:"space-between" }}>

              <a href="/">
                    <img
                      src={appLogo}
                      style={{ height: "40px" }}
                      alt="existing logo"
                    />
                  </a>
              <a href="https://dappside.io/dapps/BNBAllstars" target="_blanc" style={{ alignSelf:"center" }}>
                <img
                  src={Banner}
                  className="banner"
                  style={{ maxHeight: "40px", width: "100%", minHeight: "none", }}
                  alt="new banner"
                  />
              </a>
              
                  </div>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.menuEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(this.state.menuEl)}
                onClose={this.handleCloseNavMenu}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      if (page.name !== "Home" && page.name !== "WhitePaper") {
                        window.open(page.url, "_blank");
                      } else {
                        window.open(page.url, "_self");
                      }
                      this.handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{ flexGrow: 1, width: 1, display: { xs: "none", md: "flex" } }}
          >
            <Stack width={1} direction="row" alignItems="center">
              <Box style={{ width:"100%" }}>
                <div className="logo-container" style={{ width:"100%", justifyContent:"space-between" }}>
                  <a href="/">
                    <img
                      src={appLogo}
                      style={{ maxHeight: "63px", width:"100%" }}
                      alt="existing logo"
                    />
                  </a>
                  <a
                    href="https://dappside.io/dapps/BNBAllstars"
                    target="_blanc"
                  >
                    <img
                      src={Banner}
                      className="banner"
                      style={{ maxHeight: "60px", width: "100%" }}
                      alt="new banner"
                    />
                  </a>
                </div>
              </Box>
              <Box sx={{ flex: 1 }} />
              <Stack
                direction="row"
                alignItems="center"
                spacing={!isTablet ? 5 : 1}
                sx={{
                  "@media (max-width:1024px)": {
                    "& .tab-btn": {
                      fontSize: "14px !important",
                      lineHeight: "20px",
                    },
                  },
                }}
              >
                {pages.map((page, idx) => {
                  const isActive = page.name === "Home1" ? true : false;
                  return (
                    <Button
                      key={idx}
                      variant="text"
                      disableRipple
                      className="tab-btn"
                      sx={{
                        display: "block",
                        color: isActive ? "#F8A21B" : "#FFFFFF",
                        textAlign: "center",
                        "& .MuiButton-text": {
                          fontSize: "18px",
                          lineHeight: "22px",
                          letterSpacing: "0.14em",
                        },
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      href={page.url}
                      target={
                        page.name !== "WhitePaper" && page.name !== "Home"
                          ? "_blank"
                          : ""
                      }
                    >
                      {page.name}
                      {isActive && (
                        <Divider
                          sx={{ borderWidth: "1px", borderColor: "#F8A21B" }}
                        />
                      )}
                    </Button>
                  );
                })}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Toolbar>
    );
  };
}
export default Header;
