import Web3 from 'web3';
import BN from 'bn.js';

export function formatEthFromWei (value: string, pMaximumSignificantDigits: number = 4)  {
    // Convert the value to a BigNumber instance, rounding down to the nearest whole number
    const bnValue = new BN(value.split('.')[0]);
    value = Web3.utils.fromWei(bnValue.toString());
    return new Intl.NumberFormat('en-GB', {maximumSignificantDigits: pMaximumSignificantDigits}).format(parseFloat(value));
};


 export function formatNumber (value: any, pMaximumSignificantDigits: number = 4)  {
    return new Intl.NumberFormat('en-GB', {maximumSignificantDigits: pMaximumSignificantDigits}).format(parseFloat(value));
 };