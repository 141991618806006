import React from "react";
import {
  Button,
  StandardTextFieldProps,
  Stack,
  InputBase,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import { formatEthFromWei } from "../Formatters";
import { TeamsResponse } from "../assets/abi/farm-contract";

interface AirdropPanelProps {
  style?: React.CSSProperties;
  balance?: string;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class AirdropPanel extends React.Component<AirdropPanelProps> {
  private airdropAmountInput: React.RefObject<StandardTextFieldProps>;
  private airdropRecipientInput: React.RefObject<StandardTextFieldProps>;
  private teamAirdropRecipientInput: React.RefObject<StandardTextFieldProps>;
  private teamAirdropAmountInput: React.RefObject<StandardTextFieldProps>;

  state = {
    tabValue: 1,
    balance: "",
    tabVal: 0,
  };
  baseState: any;
  _stateInterval: any;
  balance?: string;

  constructor(props: AirdropPanelProps) {
    super(props);

    this.baseState = this.state;
    this.airdropAmountInput = React.createRef();
    this.airdropRecipientInput = React.createRef();
    this.teamAirdropRecipientInput = React.createRef();
    this.teamAirdropAmountInput = React.createRef();

    // properties
    this.balance = props.balance;
  }

  componentDidMount = () => {
    this.checkState();
    if (this._stateInterval) {
      clearInterval(this._stateInterval);
    }
    this._stateInterval = setInterval(
      this.checkState,
      window.dataRefreshInterval +
        (window.dataRefreshInterval / 100) * 30 /*40% slower*/
    );
  };

  componentWillUnmount = () => {};

  componentWillReceiveProps(props: AirdropPanelProps) {
    this.balance = props.balance;
    this.setState({ balance: this.balance });
  }

  checkState = () => {
    if (window.connected !== true) {
      this.setState(this.baseState);
      return;
    }
  };

  tabHandleChange = (event: any, newValue: any) => {
    this.setState({ tabValue: newValue });
  };

  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ tabVal: newValue });
  };

  onClick_SendAirdrop = () => {
    if (
      !this.airdropAmountInput.current ||
      !this.airdropRecipientInput.current
    ) {
      return;
    }
    let recipient = this.airdropRecipientInput.current.value as string;

    // send to address
    if (window.web3.utils.isAddress(recipient)) {
      window.farm_contract.methods
        .airdrop(recipient)
        .send({
          value: window.web3.utils.toWei(
            this.airdropAmountInput.current.value as string
          ),
          from: window.walletAddress,
        })
        .then((response: any) => {})
        .catch((err: any) => {
          console.log(err);
        });
      return;
    }

    // send to address from nickname
    if (!window.web3.utils.isAddress(recipient) && recipient.length > 0) {
      window.farm_contract.methods
        .getAddressToNickname(recipient)
        .call()
        .then((result: string) => {
          console.debug(
            "checked nickname ",
            recipient,
            " and got address ",
            result
          );
          if (result !== "0x0000000000000000000000000000000000000000") {
            if (!this.airdropAmountInput.current) {
              return;
            }
            window.farm_contract.methods
              .airdrop(result)
              .send({
                value: window.web3.utils.toWei(
                  this.airdropAmountInput.current.value as string
                ),
                from: window.walletAddress,
              })
              .then((response: any) => {})
              .catch((err: any) => {
                console.log(err);
              });
          } else {
            alert("Invalid Sponsor Address");
          }
        });

      //alert("invalid recipient");
      return;
    }
  };

  onClick_SendTeamAirdrop = () => {
    if (!this.teamAirdropRecipientInput.current) {
      return;
    }
    let recipient = this.teamAirdropRecipientInput.current.value as string;

    // send to teamid
    if (!isNaN(parseInt(recipient))) {
      window.farm_contract.methods
        .teams(recipient)
        .call()
        .then((result: TeamsResponse) => {
          console.log("result: ", result);

          if (result.created_at !== "0") {
            if (!this.teamAirdropAmountInput.current) {
              return;
            }
            window.farm_contract.methods
              .teamAirdrop(recipient, false)
              .send({
                value: window.web3.utils.toWei(
                  this.teamAirdropAmountInput.current.value as string
                ),
                from: window.walletAddress,
              })
              .then((response: any) => {})
              .catch((err: any) => {
                console.log(err);
              });
          } else {
            alert("team not found");
          }
        });

      return;
    }
  };

  render = () => {
    return (
      <>
        <Tabs
          value={this.state.tabVal}
          onChange={this.handleChange}
          sx={{
            minHeight: "auto",
            "& .MuiTabs-flexContainer": {
              gap: 3,
            },
            "& .MuiTab-root": {
              fontSize: "16px",
              lineHeight: "28px",
              fontWeight: 400,
              color: "#ffffff80",
              textTransform: "none",
              padding: "5px 0px",
              minHeight: "auto",
            },
            "& .Mui-selected": {
              color: "#FF9924 !important",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#FF9924",
            },
          }}
        >
          <Tab label="Direct Airdrop" />
          <Tab label="Team Airdrop" />
        </Tabs>
        <Box mt={7}>
          {this.state.tabVal === 0 ? (
            <Stack spacing={3}>
              <Stack direction="column" spacing={1}>
                <InputBase
                  type="text"
                  placeholder="Recipient"
                  inputRef={this.airdropRecipientInput}
                  sx={{
                    py: "18px",
                    px: "20px",
                    width: "100%",
                    border: "1px solid #ffffff4d",
                    borderRadius: "7px",

                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    color: "white",
                    "& .MuiInputBase-input": {
                      p: 0,
                    },
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #ffffff4d",
                    borderRadius: "7px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      my: "18px",
                      mx: "20px",
                    }}
                  >
                    <InputBase
                      type="number"
                      placeholder="Amount in BNB"
                      inputRef={this.airdropAmountInput}
                      defaultValue={0.1}
                      sx={{
                        width: "100%",
                        height: "100%",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        color: "white",
                        "& .MuiInputBase-input": {
                          p: 0,
                          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                            {
                              "-webkit-appearance": "none",
                              display: "none",
                            },
                        },
                      }}
                    />
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        "& svg": {
                          cursor: "pointer",
                          "& path": {
                            opacity: 0.3,
                            fill: "white",
                          },
                          "&:hover": {
                            "& path": {
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    >
                      <svg
                        width={17}
                        height={9}
                        viewBox="0 0 17 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity={0.3}
                          d="M0 9L8.5 9.53674e-07L17 9L0 9Z"
                          fill="white"
                        />
                      </svg>
                      <svg
                        width={17}
                        height={9}
                        viewBox="0 0 17 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 0L8.5 9L0 -1.48619e-06L17 0Z"
                          fill="white"
                        />
                      </svg>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Switch
                  disableRipple
                  sx={{
                    width: 38,
                    height: 15,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                      padding: 0
                    },
                    '& .MuiSwitch-thumb': {
                      width: '15px',
                      height: '15px'
                    },
                    '& 	.Mui-checked': {
                      color: '#F8A21B !important'
                    },
                    '& .Mui-checked+.MuiSwitch-track': {
                      backgroundColor: '#F8A21B !important'
                    }
                  }}
                />
                <Typography fontSize={14} fontWeight={400} color="white">
                  Also send to team owner
                </Typography>
              </Stack> */}
              <Stack direction="column" alignItems="flex-start" spacing={1}>
                <Button
                  variant="contained"
                  disableRipple
                  disabled={window.connected !== true}
                  onClick={this.onClick_SendAirdrop}
                  sx={{
                    width: "100%",
                    backgroundColor: "#F8A21B",
                    padding: "5px 32px",
                    borderRadius: "55px",
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontWeight: 400,
                    color: "#24203B",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "rgb(36 32 59)",
                      color: "white",
                    },
                  }}
                >
                  Send
                </Button>
                <Stack
                  width={1}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography fontSize={16} fontWeight={500} color="white">
                      Balance
                    </Typography>
                    <Typography
                      className="truncate-text"
                      fontSize={16}
                      fontWeight={500}
                      color="#FF9924"
                    >
                      {formatEthFromWei(this.state.balance, 6)} BNB
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing={3}>
              <Stack direction="column" spacing={1}>
                <InputBase
                  type="text"
                  placeholder="Team Id"
                  inputRef={this.teamAirdropRecipientInput}
                  sx={{
                    py: "18px",
                    px: "20px",
                    width: "100%",
                    border: "1px solid #ffffff4d",
                    borderRadius: "7px",

                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    color: "white",
                    "& .MuiInputBase-input": {
                      p: 0,
                    },
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #ffffff4d",
                    borderRadius: "7px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      my: "18px",
                      mx: "20px",
                    }}
                  >
                    <InputBase
                      type="number"
                      placeholder="Amount in BNB"
                      inputRef={this.teamAirdropAmountInput}
                      defaultValue={0.1}
                      sx={{
                        width: "100%",
                        height: "100%",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        color: "white",
                        "& .MuiInputBase-input": {
                          p: 0,
                          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                            {
                              "-webkit-appearance": "none",
                              display: "none",
                            },
                        },
                      }}
                    />
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        "& svg": {
                          cursor: "pointer",
                          "& path": {
                            opacity: 0.3,
                            fill: "white",
                          },
                          "&:hover": {
                            "& path": {
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    >
                      <svg
                        width={17}
                        height={9}
                        viewBox="0 0 17 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity={0.3}
                          d="M0 9L8.5 9.53674e-07L17 9L0 9Z"
                          fill="white"
                        />
                      </svg>
                      <svg
                        width={17}
                        height={9}
                        viewBox="0 0 17 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 0L8.5 9L0 -1.48619e-06L17 0Z"
                          fill="white"
                        />
                      </svg>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Switch
                  disableRipple
                  sx={{
                    width: 38,
                    height: 15,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                      padding: 0
                    },
                    '& .MuiSwitch-thumb': {
                      width: '15px',
                      height: '15px'
                    },
                    '& 	.Mui-checked': {
                      color: '#F8A21B !important'
                    },
                    '& .Mui-checked+.MuiSwitch-track': {
                      backgroundColor: '#F8A21B !important'
                    }
                  }}
                />
                <Typography fontSize={14} fontWeight={400} color="white">
                  Also send to team owner
                </Typography>
              </Stack> */}
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Button
                  variant="contained"
                  disableRipple
                  disabled={window.connected !== true}
                  onClick={this.onClick_SendTeamAirdrop}
                  sx={{
                    width: "100%",
                    backgroundColor: "#F8A21B",
                    padding: "5px 32px",
                    borderRadius: "55px",
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontWeight: 400,
                    color: "#24203B",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#F8A21B",
                    },
                  }}
                >
                  Send
                </Button>
                <Stack
                  width={1}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography fontSize={16} fontWeight={500} color="white">
                      Balance
                    </Typography>
                    <Typography
                      className="truncate-text"
                      fontSize={16}
                      fontWeight={500}
                      color="#FF9924"
                    >
                      {formatEthFromWei(this.state.balance, 6)} BNB
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </>
    );
  };
}

export default AirdropPanel;
