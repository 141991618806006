/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-self-assign */
import React from "react";
import { Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { formatEthFromWei } from "../Formatters";
import {
  TeamInfoResponse,
  UserInfoResponse,
} from "../assets/abi/farm-contract";

interface TeamViewProps {
  style?: React.CSSProperties;
  className?: string;
  teamId: string;
  defaultname: string;
}

const promisify = (inner: Function) =>
  new Promise((resolve, reject) =>
    inner((res: any, err: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    })
  );

class TeamView extends React.Component<TeamViewProps> {
  className?: string;
  style?: React.CSSProperties;
  teamId: string;
  defaultname: string;

  state = {
    members: [],
    nicknames: [],
    owner: "",
    id: "",
    created_at: "",
    name: "",
    is_referral_team: false,

    showMembers: false,
    teamName: "",
    memberInfos: [],
    showDeposits: false,
  };
  baseState: any;
  _stateInterval: any;

  constructor(props: TeamViewProps) {
    super(props);

    this.baseState = this.state;

    // properties
    this.className = props.className;
    this.style = props.style;

    this.teamId = props.teamId;
    this.defaultname = props.defaultname;
  }

  componentDidMount = () => {
    this.checkState();
    if (this._stateInterval) {
      clearInterval(this._stateInterval);
    }
    this._stateInterval = setInterval(
      this.checkState,
      window.dataRefreshInterval * 2
    );

    this.state.teamName = this.defaultname;
  };

  componentWillReceiveProps(props: TeamViewProps) {
    this.teamId = props.teamId;
    this.defaultname = props.defaultname;
    this.setState({
      teamName: this.defaultname,
    });
  }

  componentWillUnmount = () => {};

  onClick_AddMember = () => {};

  onChange_ShowDeposits = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.showDeposits = event.target.checked;

    this.getAllDeposits();
  };

  getAllDeposits = () => {
    this.setState({
      memberInfos: [], // empty
    });

    if (this.state.showDeposits === true) {
      let promises = [];
      for (let i = 0; i < this.state.members.length; i++) {
        promises.push(
          promisify((callback: any) =>
            window.farm_contract.methods
              .userInfo(this.state.members[i])
              .call()
              .then(callback)
          )
        );
      }

      Promise.all(promises).then((results) => {
        this.setState({
          memberInfos: results,
        });
      });
    }
  };

  checkState = () => {
    if (window.connected !== true) {
      this.setState(this.baseState);
      return;
    }

    if (this.teamId !== "") {
      window.farm_contract.methods
        .teamInfo(this.teamId)
        .call()
        .then((result: TeamInfoResponse) => {
          let teamName = "Team " + result._team.id; // default = Team + id
          if (result._team.is_referral_team === true) {
            teamName = this.defaultname; // if team is ref-team...
          }
          if (result._team.name.length > 0) {
            teamName = result._team.name; // name override
          }

          teamName = teamName;

          this.setState({
            nicknames: result.nicks,
            members: result._team.members,
            showMembers: result._team.members.length > 0,
            owner: result._team.owner,
            id: result._team.id,
            created_at: result._team.created_at,
            name: result._team.name,
            is_referral_team: result._team.is_referral_team,
            teamName: teamName,
          });
        });
    } else {
      this.setState(this.baseState);
    }
    this.setState({
      memberInfos: [], // empty
    });
  };

  render = () => {
    let memberList;
    if (this.state.showMembers) {
      memberList = this.state.members.map((walletAddress, index) => {
        let nick = this.state.nicknames[index] as string;
        if (this.state.owner === walletAddress) {
          nick = nick + " (owner)";
        }

        if (this.state.memberInfos[index]) {
          let memberInfo = this.state.memberInfos[index] as UserInfoResponse;
          nick =
            nick + " (" + formatEthFromWei(memberInfo.deposit_amount) + " BNB)";
        }
        return (
          <Stack key={index}>
            <Typography
              className="truncate-text"
              fontSize={14}
              fontWeight={500}
              color="#ffffffb3"
            >
              {walletAddress}
            </Typography>
            <Typography fontSize={12} fontWeight={500} color="#ff9924b3">
              {nick}
            </Typography>
          </Stack>
        );
      });
    } else {
      memberList = (
        <Typography
          className="truncate-text"
          fontSize={14}
          fontWeight={500}
          color="#ff9924b3"
        >
          User has no members yet.
        </Typography>
      );
    }

    return (
      <>
        {/* <Button
          variant="outlined"
          disableRipple
          sx={{
            borderColor: "#F8A21B",
            "&:hover": {
              background: "transparent",
              borderColor: "#F8A21B",
            },
          }}
        >
          <svg
            width={18}
            height={20}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 10V7C1 6.20435 1.31607 5.44129 1.87868 4.87868C2.44129 4.31607 3.20435 4 4 4H17M17 4L14 1M17 4L14 7M17 10V13C17 13.7956 16.6839 14.5587 16.1213 15.1213C15.5587 15.6839 14.7956 16 14 16H1M1 16L4 19M1 16L4 13"
              stroke="#F8A21B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button> */}
        <Stack mt={1.5} spacing={1}>
          <Typography fontSize={16} fontWeight={500} color="white">
            {this.state.teamName}:
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontSize={16} fontWeight={500} color="white">
              Team-Id:
            </Typography>
            <Typography fontSize={16} fontWeight={500} color="#FF9924">
              {this.state.id}
            </Typography>
          </Stack>
          {/* <Typography fontSize={16} fontWeight={500} color="#FF9924">
            Not Loaded Yet
          </Typography> */}
          <Stack
            mt={2}
            spacing={1}
            sx={{
              height: "350px",
              overflow: "auto",
              "@media (max-width:767px)": {
                "& .truncate-text": {
                  width: "80% !important",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              },
            }}
          >
            {memberList}
          </Stack>
        </Stack>
      </>
    );
  };
}

export default TeamView;
