import React from "react";
import { AppBar, Container, Box } from "@mui/material";
import Header from "../components/Header";
import roadmap from "../assets/img/roadmap.png";

interface AppProps {
  style?: React.CSSProperties;
}

export default class Whitepaper extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  render = () => {
    return (
      <>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            paddingBottom: "100px",
            background: "#24203B",
            "@media (max-width:767px)": {
              "& .height-bg": {
                height: "880px",
              },
            },
          }}
        >
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{
              width: `100%`,
              backgroundColor: "transparent",
            }}
          >
            <Container maxWidth="lg">
              <Header />
              <img
                src={roadmap}
                alt=""
                style={{ marginTop: "30px", width: "inherit" }}
              />
              <div className="py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
                <div className="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:max-w-lg sm:mx-auto sm:rounded-lg sm:px-10">
                  <div className="max-w-md mx-auto">
                    <div className="divide-y divide-gray-300/50">
                      <div className="py-8 text-base leading-7 space-y-6 text-gray-600">
                        <p>
                          BNB ALL-Stars is a new platform to support a growing
                          ecosystem of innovative distributed, non-custodial,
                          open-source, and audited Play-to-Earn applications.
                          This project was created by a well-known and trusted
                          team, supported by many people, and is poised to be
                          the DApp of 2023.
                        </p>
                        <p>
                          This whitepaper provides a guide to understanding how
                          the initial platform works, its features, how you can
                          help grow the community, and how you can benefit.
                        </p>
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                          Table of Contents
                        </h2>
                        <ol className="list-decimal list-inside space-y-3">
                          <li> Participation</li>
                          <li> Deposits</li>
                          <li> Withdrawals</li>
                          <li> Anti Whale Withdrawal Taxes</li>
                          <li> Compounding </li>
                          <li> Referral System</li>
                          <li> Airdrops</li>
                          <li> Teams</li>
                          <li> Administrative Controls</li>
                          <li> Important Notes</li>
                        </ol>
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                          PARTICIPATION
                        </h2>
                      </div>{" "}
                    </div>
                    <div>
                      {" "}
                      <ul>
                        <li>
                          Participants can join the BNB ALL-Stars Project by
                          depositing a minimum of 0.1 BNB.
                        </li>
                        <li>
                          By depositing, participants become eligible to receive
                          365% back in three different ways.
                        </li>
                        <li>
                          The 365% is returned through passive reinvestments and
                          via 3 layers of referral, match, and airdrop bonuses.
                        </li>
                        <li>
                          Participants reinvest fees to increase their daily
                          1.5% rate per day and their total income to 365%.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      DEPOSITS
                    </h1>{" "}
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      DEPOSIT AMOUNT
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        The minimum deposit amount is 0.1 BNB, and the maximum
                        deposit limit is 25 BNB per wallet.
                      </li>
                      <li>
                        A 10% deposit tax is sent to the team to support the
                        project operation, marketing, and development.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      UPLINE SETTING
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        The referrer (upline) is established during the deposit
                        process.
                      </li>
                      <li>
                        The upline can earn a 5% direct referral commission from
                        the deposits made by their downline.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      DOWNLINE DEPOSITS{" "}
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        Downline deposits are tracked to manage the hierarchical
                        structure of referrals.
                      </li>
                      <li>
                        The system supports up to three levels of downline,
                        allowing for a broader network of earnings.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      WITHDRAWALS
                    </h1>{" "}
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      PAYOUT CALCULATION{" "}
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        The payout includes a deposit payout, direct bonus,
                        match, and airdrop bonus.
                      </li>
                      <li>
                        The direct bonus is a 5% commission earned from the
                        deposits made by the downline.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      WITHDRAWAL TAX{" "}
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        An anti-whale tax is applied, ranging from 5% to 50%
                        depending on the payout amount relative to the contract
                        balance. This tax amount stays in the contract.
                      </li>
                      <li>
                        Additional anti-whale withdrawal taxes** are applied
                        based on the withdrawal amount.
                      </li>
                      <li>
                        A 10% withdrawal tax is sent to the team to support the
                        project operation, marketing, and development.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      ACTION COOLDOWN{" "}
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        Reinvestments and withdraws can only be made after a 24
                        hour cooldown period.
                      </li>
                      <li>
                        The cooldown period ensures stability in the fund and
                        prevents rapid withdrawals.
                      </li>
                    </ul>
                  </div>
                  <div>
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  REINVEST
                    </h1>{" "}
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      MANDATORY REINVEST{" "}
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                      This feature encourages long-term participation and growth of the fund
                      </li>
                      <li>
                      Users must reinvest 3x before being allowed to withdraw. Project Manager does have the option to disable this 
                      </li>                   
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    feature{" "}
                    </h2>{" "}
                  </div>

                  <div>
                    {" "}
                    <ul>
                      <li>
                      The reinvest count must not exceed 5x. This limitation is in place to maintain the stability of the fund.
                      </li>               
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    Reinvestment Bonus{" "}
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                      A 1% bonus is applied for reinvestment. This bonus serves as an incentive for users to reinvest their earnings.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      ANTI WHALE WITHDRAWAL TAXES{" "}
                    </h1>{" "}
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      Anti-whale taxes in the project are scaled based on the
                      amount of the withdrawal compared to the total amount of
                      the contract balance.
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <li>
                        0% tax additional if amount is less then 1% of contract
                        balance
                      </li>
                      <li>
                        {" "}
                        5% tax additional if amount is less then or equal to 1%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        10% tax additional if amount is less then or equal to 2%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        15% tax additional if amount is less then or equal to 3%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        20% tax additional if amount is less then or equal to 4%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        25% tax additional if amount is less then or equal to 5%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        30% tax additional if amount is less then or equal to 6%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        35% tax additional if amount is less then or equal to 7%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        40% tax additional if amount is less then or equal to 8%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        45% tax additional if amount is less then or equal to 9%
                        of contract balance
                      </li>
                      <li>
                        {" "}
                        50% tax additional if amount is less then or equal to
                        10% of contract balance
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      REFERRAL SYSTEM
                    </h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <ul>
                      <h3 style={{ fontWeight: "900" }}>Direct Bonus</h3>
                      <li>
                        Given to the referrer based on the referrals' deposits.
                        This bonus is a part of the multi-level referral system.
                      </li>
                      <h3 style={{ fontWeight: "900" }}>Match Bonus </h3>
                      <li>
                        Given based on the referrals' earnings. The match bonus
                        can go up to 10% of the referral's daily income.
                      </li>
                      <h3 style={{ fontWeight: "900" }}>Referral Payout</h3>
                      <li>
                        Distributed across different levels in the referral
                        hierarchy, handled by the _refPayout function. This
                        ensures a fair distribution of rewards.
                      </li>
                    </ul>
                  </div>{" "}
                </div>
                <div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      AIRDROPS
                    </h2>{" "}
                  </div>
                  <div>
                    <ul>
                      <h3 style={{ fontWeight: "900" }}>Airdrop Bonus</h3>
                      <li>
                        Distributed to specific addresses or teams. These
                        bonuses can be triggered by the team leaders or
                        projectManager.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <h3 style={{ fontWeight: "900" }}>Team Airdrops</h3>
                      <li>
                        Distributed to specific teams, optionally excluding the
                        team owner. This allows for targeted community building.{" "}
                      </li>
                    </ul>
                  </div>
                  
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      TEAMS
                    </h2>{" "}
                  </div>

                  <div>
                    <ul>
                      <li>
                        Members can be added to a team's member list. There is
                        no maximum limit on the number of members in a team.
                      </li>
                      <li>One account can have a maximum of 6 teams</li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    ADMINISTRATIVE CONTROLS
                    </h2>{" "}
                  </div>
                  <div>
                    <ul>
                     
                      <li>
                        The projectManager can enable/disable the airdrop and
                        mandatory reinvestment features
                      </li>
                      <li>
                        Based on project participation projectManager can also
                        increase referral rewards to 3-layers of 10%.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    IMPORTANT NOTES
                    </h2>{" "}
                  </div>
                  <div>
                    <ul>
                      <li>
                        BNB ALL-Stars is a community-based project, 100%
                        decentralized, P2P transactions through Smart Contract.
                      </li>
                      <li>Each Deposit Cycle is 365%.</li>
                      <li>
                        When 365% is received, the User should make a new
                        deposit to increase their max payout and continue
                        earning passively.
                      </li>
                      <li>
                        The minimum investment is 0.1 BNB, open to all
                        participants.
                      </li>
                      <li>
                        No referrals are required to receive the 1.5% daily.
                        Referrals will just help them reach the 365% faster.
                      </li>
                      <li>
                        Increase the speed of your returns by growing the
                        community through marketing/sharing of the project.
                      </li>
                      <li>
                        The maximum amount to receive per wallet is limited to
                        200 BNB.
                      </li>
                      <li>
                        Only one action (reinvest/withdraw) is allowed within 24
                        hours.
                      </li>
                      <li>
                        After 48h the accumulation of the unwithdrawn income
                        will freeze.
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    BNB-AllStars : Q & A{" "}
                  </h1>{" "}
                </div>
      
              </div>
              <div> Reinvestment </div>
              <br />
              <div>
                {" "}
                Q: How does reinvesting affect my daily rate and total income?
              </div>
              <div>
                {" "}
                A:  Reinvesting your earnings will increase your daily rate per day and your total income potential by 365%.
              </div>
              <br />
              <div> Q:  Are there any fees for reinvesting?</div>
              <div>
                {" "}
                A: No, participants do not incur any additional fees when reinvesting their earnings. The only associated cost is the network gas fees associated with the transactions.

              </div>
              <br />
              ---
              <div>Match Bonus on Referrals</div>
              <br />
              <div> Q: What is the Match Bonus feature?</div>
              <div>
                {" "}
                A: The Match Bonus feature allows you to earn additional income based on the daily income of your referrals, up to three levels.
              </div>
              <br />
              <div> Q: How is the match bonus calculated?</div>
              <div>
                {" "}
                A: The match bonus is 5% for level 1 referrals, 5% for level 2, and 5% for level 3.projectManager can increase referrals to 10% for level 1, 10% for level 2, and 10% for level 3. They cannot be changed back.
              </div>
              <br />
              <div> Q: Is there a limit on how many referrals I can have?</div>
              <div>
                {" "}
                A: No, there is no limit on the number of referrals you can
                have.
              </div>
              <br />
              ---
              <div> Daily Returns and Withdrawa</div>
              <br />
              <div> Q: What are the daily returns on my deposit?</div>
              <div>
                {" "}
                A:Participants receive a 1.5% daily return on their deposited
                amount.
              </div>
              <br />
              <div> Q: Can I withdraw my earnings daily?</div>
              <div>
                {" "}
                A: Yes, you have the option to withdraw your payout every day,
                with rewards accumulating over a 24-hour period.
              </div>
              <br />
              <div> Q: Is there a minimum withdrawal amount?</div>
              <div>
                {" "}
                A: No
              </div>
              <br />
              ---
              <div> Anti-Whale Measures</div>
              <br />
              <div>
                {" "}
                Q: Are there any additional fees for large withdrawals?
              </div>
              <div>
                {" "}
                A: Yes, there are additional anti-whale taxes for withdrawing.
              </div>
              <br />
              <div> Q: How are these anti-whale taxes calculated?</div>
              <div>
                {" "}
                A: The system will calculate the additional anti-whale taxes
                based on your withdrawal amount.
              </div>
              <br />
              <div> Q: Can I avoid anti-whale taxes?</div>
              <div>
                {" "}
                A:  Anti-whale taxes are automatically applied to large withdrawals and cannot be avoided.
Team and Referral Management
              </div>
              <br />
              ---
              <div> Team Management</div>
              <br />
              <div> Q: How do I create a team?</div>
              <div>
                {" "}
                A: A new team will be created when someone uses your referral link, making you the team owner.
              </div>
             
          
            </Container>
          </AppBar>
        </Box>
      </>
    );
  };
}
