import React from "react";
import "./App.css";
import {
  AppBar,
  Container,
  Button,
  Typography,
  Stack,
  StandardTextFieldProps,
  InputBase,
  Box,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { AbiItem, TransactionReceipt } from "ethereum-abi-types-generator";
import {
  ContractContext,
  ContractInfoResponse,
  PayoutOfResponse,
  UserDirectTeamsInfoResponse,
  UserInfo2Response,
  UserInfoResponse,
  UserInfoTotalsResponse,
  userBonusStatsInfoResponse,
} from "./assets/abi/farm-contract";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import copy from "clipboard-copy";
import { formatEthFromWei, formatNumber } from "./Formatters";
import ReactCountDown from "./components/ReactCountDown";
import abi from "./assets/abi/farm-contract.json";
import {
  DashboardWrapper,
  MainWrapper,
  DepositWrapper,
  StatisticsBoxWrapper,
} from "./components";
import Header from "./components/Header";
import ConnectButton from "./components/ConnectButton";
import Advertisements from "./components/Advertisements";
import TeamView from "./components/TeamView";
import AirdropPanel from "./components/AirdropPanel";
import SettingsDlg from "./components/SettingsDlg";

import mainBg from "./assets/img/bg-1.png";
import mediumWheel from "./assets/img/wheel-3.png";
import depositIcon from "./assets/img/deposit-icon.png";
import payoutIcon from "./assets/img/payout-icon.png";
import unwithdrawnIcon from "./assets/img/unwithdrawn-icon.png";
import withdrawnIcon from "./assets/img/withdrawn-icon.png";
import totalRoiIcon from "./assets/img/total-roi-icon.png";
import airdropIcon from "./assets/img/airdrop-icon.png";
import clockIcon from "./assets/img/clock-icon.png";

const contractAddress: string = "0x229EB38D9F66ed6E5AefC89c57Ceea8DdA07aE25";
declare global {
  interface Window {
    dataRefreshInterval: number;
    farm_contract: ContractContext;
    web3: Web3;
    walletConnectProvider: WalletConnectProvider;
    ethereum: any;
    contractAddress: string;
    contract_abi: AbiItem[];
    walletAddress: string;
    connected: boolean;
    refferalAddress: string;
    allowance: number;
  }
}
interface AppProps {
  style?: React.CSSProperties;
}
window.dataRefreshInterval = 3000;
class App extends React.Component<AppProps> {
  state = {
    balance: "",
    contractBalance: "",
    sponsorAddress: "",
    modalOpen: false,
    referralFromUrl: "",
    totalDeposits: "",
    withdrawnByMembers: "",
    maximumAmountFromTopReferrer: "",
    referredBy: "?",
    myUpline: "",
    yourAddress: "?",
    nextIncomeCountdown: "00:00:00",
    endTime: 1,
    incomeLimitRemain: "",
    dailyIncome: "",
    dailyIncomeExpected: 0,
    myCurDeposit: "0",
    myCurWithdraw: "",
    directReferralIncome: "",
    matchingBonus: "",
    unwithdrawnIncome: "",
    totalAmountOfDepositedBNB: "",
    totalAmountOfReceivedBNB: "",
    totalReturnOfInvestment: "",
    personalInvitedPartners: "0",
    totalNumberOfPartners: "0",
    allDownlinesInvestments: "",
    projectWallet: "",
    totalUsers: "?",
    totalReinvested: "",
    withdrawPossible: false,
    myAirdrops: "",
    myTotalReceivedAirdrops: "",
    totalAirdrops: "",
    myLastAirdropSent: "",
    myReinvestedCount: "0",
    referral_team: "",
    upline_team: "",
    nickname: "",
    countdownVisible: true,
    cutoff: 1,
    maxMultiplier: "0",
    maxDeposit: "0",
    directBonusW: "0",
    matchBonusW: "0",
    airdropsW: "0",
    incomeReinvested: "0",
    bonusReinvested: "0",
    airdropsReinvested: "0",
    reinvestedGross: "0",
    bnbValue: 0,
    tabVal: 0,
  };

  private defaultSponsorAddress: string =
    "0xaa9EA734eb308e0A44EfE5Bae8A30E076970fc49";
  private amountInput: React.RefObject<StandardTextFieldProps>;
  private sponsorInput: React.RefObject<StandardTextFieldProps>;
  baseState: any;
  _stateInterval: any;
  _priceInterval: any;
  constructor(props: AppProps) {
    super(props);

    this.baseState = this.state;
    this.amountInput = React.createRef();
    this.sponsorInput = React.createRef();
  }

  private _modalInterval: any = null;
  private CountDownDate: Date = new Date();

  componentDidMount = () => {
    window.contractAddress = contractAddress;
    window.contract_abi = abi as AbiItem[];
    //const urlParams = new URLSearchParams(window.location.search);
    /*let unlock = urlParams.get('unlock');
    if(unlock != "true"){
      this._modalInterval = setInterval(this.handleModal, 1000);
    }else{
      this.state.countdownVisible = false;
    } */
    this.checkState();
    this.getBNBValue();
    setTimeout(() => {
      this.checkState();
    }, window.dataRefreshInterval);

    if (this._stateInterval) {
      clearInterval(this._stateInterval);
    }

    this._stateInterval = setInterval(
      this.checkState,
      window.dataRefreshInterval
    );

    if (this._priceInterval) {
      clearInterval(this._priceInterval);
    }

    this._stateInterval = setInterval(this.getBNBValue, 60 * 1000);
  };

  handleModal = () => {
    let countDownDateUTC = new Date();
    countDownDateUTC.setUTCDate(29);
    countDownDateUTC.setUTCMonth(11);
    countDownDateUTC.setUTCFullYear(2021);
    countDownDateUTC.setUTCHours(14);
    countDownDateUTC.setUTCMinutes(0);
    countDownDateUTC.setUTCSeconds(0);
    this.CountDownDate = countDownDateUTC;
    if (new Date().getTime() < countDownDateUTC.getTime()) {
      this.setModalOpen();
      this.setState({ countdownVisible: true });
    } else {
      if (this._modalInterval != null) {
        clearInterval(this._modalInterval);
        this._modalInterval = null;
        this.setState({ countdownVisible: false });
        this.setModalClose();
      }
    }
  };

  getBNBValue = async () => {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd&precision=6";
    new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (!data["binancecoin"]) {
            throw new Error("Token data is missing from response");
          }
          this.setState({
            bnbValue: data["binancecoin"]["usd"],
          });
          resolve(data["binancecoin"]["usd"]);
        })
        .catch((error) => {
          console.error("Error fetching token price:", error);
          reject(error);
        });
    });
  };

  checkState = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let refAddress = urlParams.get("ref");
    if (refAddress != null) {
      window.refferalAddress = refAddress;
      if (
        this.state.myUpline === "" ||
        this.state.myUpline === "0x0000000000000000000000000000000000000000"
      ) {
        this.setState({
          referralFromUrl:
            "Referral bonus from your investment will go to " +
            window.refferalAddress,
        });
      } else {
        this.setState({
          referralFromUrl: "",
        });
      }
      if (this.sponsorInput.current != null) {
        this.setState({ sponsorAddress: window.refferalAddress });
      }
    }

    if (window.connected !== true) {
      this.setState(this.baseState);
      return;
    }
    this.setState({
      yourAddress: window.walletAddress,
    });
    window.farm_contract.methods
      .projectManager()
      .call()
      .then((result: string) => {
        if (!window.web3.utils.isAddress(this.state.projectWallet)) {
          this.setState({
            projectWallet: result,
          });
          if (this.sponsorInput.current != null) {
            //this.sponsorInput.current.value = result;
            // this.forceUpdate();
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    window.farm_contract.methods
      .contractInfo()
      .call()
      .then((result: ContractInfoResponse) => {
        this.setState({
          totalUsers: result._total_users, //new
          totalDeposits: result._total_deposited,
          withdrawnByMembers: result._total_withdraw,
          totalAirdrops: result._total_airdrops,
        });
        window.farm_contract.methods
          .total_reinvested()
          .call()
          .then((result: string) => {
            this.setState({
              totalReinvested: result,
            });
          });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.web3.eth
      .getBalance(window.walletAddress)
      .then((result) => {
        this.setState({ balance: result });
      })
      .catch((err: any) => {
        console.log(err);
      });

    window.web3.eth
      .getBalance(contractAddress)
      .then((result) => {
        this.setState({ contractBalance: result });
      })
      .catch((err: any) => {
        console.log(err);
      });

    window.farm_contract.methods
      .userInfo(window.walletAddress)
      .call()
      .then((result: UserInfoResponse) => {
        let endTime = 1;
        let cutoff = 1;
        let nextIncomeCountdown = result.checkpoint;
        if (nextIncomeCountdown === "0") {
          nextIncomeCountdown = "Will show after deposit";
          endTime = 1;
          cutoff = 1;
        } else {
          nextIncomeCountdown = new Date(
            (parseInt(result.checkpoint) + 86400) * 1000
          ).toLocaleString();
          endTime = (parseInt(result.checkpoint) + 86400) * 1000;
          cutoff = (parseInt(result.checkpoint) + 2 * 86400) * 1000;
        }
        this.setState({
          referredBy:
            result.upline === "0x0000000000000000000000000000000000000000"
              ? "Will show after deposit"
              : result.upline,
          myUpline: result.upline,
          nextIncomeCountdown: nextIncomeCountdown,
          endTime: endTime,
          cutoff: cutoff,
          myCurDeposit: result.deposit_amount,
          myCurWithdraw: result.payouts,
          directReferralIncome: result.direct_bonus,
          matchingBonus: result.match_bonus,
          unwithdrawnIncome:
            parseFloat(window.web3.utils.fromWei(result.direct_bonus)) +
            parseFloat(window.web3.utils.fromWei(this.state.dailyIncome)) +
            parseFloat(window.web3.utils.fromWei(result.match_bonus)) +
            parseFloat(
              window.web3.utils.fromWei(this.state.myTotalReceivedAirdrops)
            ),
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .payoutOf(window.walletAddress)
      .call()
      .then((result: PayoutOfResponse) => {
        let withdrawPossible = this.state.withdrawPossible;
        if (window.web3.utils.fromWei(result.payout) !== "0") {
          withdrawPossible = true;
        }
        this.setState({
          incomeLimitRemain:
            3.65 *
              parseFloat(window.web3.utils.fromWei(this.state.myCurDeposit)) -
            parseFloat(window.web3.utils.fromWei(this.state.myCurWithdraw)),
          dailyIncomeExpected:
            (parseFloat(window.web3.utils.fromWei(this.state.myCurDeposit)) /
              100) *
            1.5,
          dailyIncome: result.payout,
          withdrawPossible: withdrawPossible,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .userInfoTotals(window.walletAddress)
      .call()
      .then((result: UserInfoTotalsResponse) => {
        this.setState({
          totalAmountOfDepositedBNB: result.total_deposits,
          totalAmountOfReceivedBNB: result.total_payouts,
          totalReturnOfInvestment:
            (parseFloat(window.web3.utils.fromWei(result.total_payouts)) /
              parseFloat(window.web3.utils.fromWei(result.total_deposits))) *
            100,
          personalInvitedPartners: result.referrals,
          allDownlinesInvestments: result.total_downline_deposit,
          totalNumberOfPartners: result.total_structure,
          myTotalReceivedAirdrops: result.airdrops_received,
          myAirdrops: result.airdrops_total,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .userInfo2(window.walletAddress)
      .call()
      .then((result: UserInfo2Response) => {
        let lastSent = "never";
        if (result.last_airdrop !== "0") {
          lastSent = new Date(
            parseInt(result.last_airdrop) * 1000
          ).toLocaleString();
        }
        this.setState({
          myLastAirdropSent: lastSent,
          myReinvestedCount: result.reinvest_count,
          nickname: result.nickname,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .userDirectTeamsInfo(window.walletAddress)
      .call()
      .then((result: UserDirectTeamsInfoResponse) => {
        this.setState({
          referral_team: result.referral_team_exists
            ? result.referral_team
            : "",
          upline_team: result.upline_team_exists ? result.upline_team : "",
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .MAX_REINVEST_MULTIPLIER()
      .call()
      .then((result: string) => {
        this.setState({
          maxMultiplier: result,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .MAX_WALLET_DEPOSIT()
      .call()
      .then((result: string) => {
        this.setState({
          maxDeposit: result,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
    window.farm_contract.methods
      .userBonusStats(window.walletAddress)
      .call()
      .then((result: userBonusStatsInfoResponse) => {
        this.setState({
          directBonusW: result.direct_bonus_withdrawn,
          matchBonusW: result.match_bonus_withdrawn,
          airdropsW: result.airdrops_withdrawn,
          incomeReinvested: result.income_reinvested,
          bonusReinvested: result.bonus_reinvested,
          airdropsReinvested: result.airdrops_reinvested,
          reinvestedGross: result.reinvested_gross,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  setModalOpen = () => {
    this.setState({ modalOpen: true });
  };
  setModalClose = () => {
    clearInterval(this._modalInterval);
    this._modalInterval = null;
    this.setState({ modalOpen: false });
  };
  copyRefToClipboard = () => {
    let referralPart = this.state.nickname
      ? this.state.nickname
      : window.walletAddress;
    let refAddress = window.location.origin + "?ref=" + referralPart;
    copy(refAddress);
  };
  onClick_Invest = () => {
    if (!this.amountInput.current || !this.sponsorInput.current) {
      return;
    }
    let ref = window.refferalAddress;
    if (!window.web3.utils.isAddress(ref)) {
      ref = this.sponsorInput.current.value as string;
      if (
        this.state.myUpline === "" ||
        this.state.myUpline === "0x0000000000000000000000000000000000000000"
      ) {
        if (ref.length > 0 && !window.web3.utils.isAddress(ref)) {
          window.farm_contract.methods
            .getAddressToNickname(ref)
            .call()
            .then((result: string) => {
              if (result !== "0x0000000000000000000000000000000000000000") {
                if (!this.amountInput.current) {
                  return;
                }
                console.debug("invest with nickname ", ref);
                window.farm_contract.methods
                  .depositWithNickname(ref)
                  .send({
                    value: window.web3.utils.toWei(
                      this.amountInput.current.value as string
                    ),
                    from: window.walletAddress,
                  })
                  .then((response: any) => {})
                  .catch((err: any) => {
                    console.log(err);
                  });
              } else {
                alert("Invalid Sponsor Address");
                return;
              }
            });
          return;
        } else if (ref.length === 0) {
          if (
            window.confirm(
              "You cannot join without a sponsor. would you like to use the project's default sponsor to join?"
            ) === true
          ) {
            this.setState({ sponsorAddress: this.defaultSponsorAddress });
          } else {
            return;
          }
        } else {
          if (
            !window.web3.utils.isAddress(ref) ||
            ref === window.walletAddress
          ) {
            alert("Sponsor Addres not valid");
            return;
          }
        }
      } else {
        ref = this.state.myUpline;
      }
    }
    if (ref.length === 0) {
      //ref = this.state.projectWallet;
      ref = this.defaultSponsorAddress;
    }

    if (!this.amountInput.current) {
      return;
    }
    console.debug("invest with address ", ref);
    window.farm_contract.methods
      .deposit(ref)
      .send({
        value: window.web3.utils.toWei(
          this.amountInput.current.value as string
        ),
        from: window.walletAddress,
      })
      .then((response: any) => {})
      .catch((err: any) => {
        console.log(err);
      });
  };
  onClick_Reinvest = () => {
    window.farm_contract.methods
      .reinvest()
      .send({ from: window.walletAddress })
      .then((result: TransactionReceipt) => {
        alert("Re-Invest Transaction Submitted.");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  onClick_Withdraw = () => {
    window.farm_contract.methods
      .withdraw()
      .send({ from: window.walletAddress })
      .then((result: TransactionReceipt) => {
        alert("Withdraw Transaction Submitted.");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ tabVal: newValue });
  };

  render = () => {
    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          background: "#24203B",
          "@media (max-width:767px)": {
            "& .height-bg": {
              height: "8800px",
            },
          },
        }}
      >
        <AppBar
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            width: `100%`,
            backgroundColor: "transparent",
          }}
        >
          <Container maxWidth="lg">
            <Header />
          </Container>
        </AppBar>
        <MainWrapper>

          <Container maxWidth="lg">
            <Advertisements />
            <Box className="dashboard">
              <Box sx={{ display: "flex" }}>
                <Stack
                  className="opacity-stack-row"
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box className="border-box">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography fontSize={16} fontWeight={400} color="white">
                        Your Received BNB
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#F8A21B"
                      >
                        {formatEthFromWei(
                          this.state.totalAmountOfReceivedBNB,
                          6
                        )}{" "}
                        BNB
                      </Typography>
                    </Stack>
                  </Box>
                  <Box className="border-box">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography fontSize={16} fontWeight={400} color="white">
                        Your Reinvest Count
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#F8A21B"
                      >
                        {this.state.myReinvestedCount}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box className="border-box">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography fontSize={16} fontWeight={400} color="white">
                        Real Deposits
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#F8A21B"
                      >
                        {formatEthFromWei(
                          this.state.totalAmountOfDepositedBNB,
                          6
                        )}{" "}
                        BNB
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Box>

              <DashboardWrapper>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={depositIcon}
                      style={{ width: "22px", height: "22px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      My Deposits
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {formatEthFromWei(this.state.myCurDeposit, 6)} BNB
                    </Typography>
                    <Typography fontSize={18} fontWeight={400} color="white">
                      ($
                      {formatEthFromWei(
                        (
                          Number(this.state.myCurDeposit) *
                          Number(this.state.bnbValue)
                        ).toString(),
                        6
                      )}
                      )
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={payoutIcon}
                      style={{ width: "16px", height: "16px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Max Payout Left
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {formatNumber(this.state.incomeLimitRemain, 6)} BNB
                    </Typography>
                    <Typography fontSize={18} fontWeight={400} color="white">
                      ($
                      {formatNumber(
                        (
                          Number(this.state.incomeLimitRemain) *
                          Number(this.state.bnbValue)
                        ).toString(),
                        6
                      )}
                      )
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={unwithdrawnIcon}
                      style={{ width: "17px", height: "17px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Unwithdrawn
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {formatNumber(this.state.unwithdrawnIncome, 6)} BNB
                    </Typography>
                    <Typography fontSize={18} fontWeight={400} color="white">
                      ($
                      {formatNumber(
                        (
                          Number(this.state.unwithdrawnIncome) *
                          Number(this.state.bnbValue)
                        ).toString(),
                        6
                      )}
                      )
                    </Typography>
                  </Stack>
                  <Stack>
                    {this.state.cutoff === 1 && (
                      <Typography
                        mt={0.5}
                        fontSize={16}
                        fontWeight={400}
                        color="#FF0000"
                      >
                        Accumulation Stopped
                      </Typography>
                    )}
                    <Stack
                      mt={2}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button
                        variant="contained"
                        disableRipple
                        disabled={
                          !this.state.withdrawPossible ||
                          Date.now() <= this.state.endTime
                        }
                        sx={{
                          backgroundColor: "#F8A21B",
                          borderRadius: "55px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#24203B",
                          "&:hover": {
                            backgroundColor: "rgb(36 32 59)",
                            color: "white",
                          },
                          "@media (max-width:767px)": {
                            fontSize: "12px",
                          },
                        }}
                        onClick={this.onClick_Reinvest}
                      >
                        Reinvest (+1% Bonus)
                      </Button>
                      <Button
                        variant="outlined"
                        disableRipple
                        disabled={
                          !this.state.withdrawPossible ||
                          Date.now() <= this.state.endTime
                        }
                        sx={{
                          borderColor: "#F8A21B",
                          borderRadius: "55px",
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#F8A21B",
                          "&:hover": {
                            backgroundColor: "#F8A21B",
                            color: "white",
                          },
                          "@media (max-width:767px)": {
                            fontSize: "12px",
                          },
                        }}
                        onClick={this.onClick_Withdraw}
                      >
                        Withdraw
                      </Button>
                    </Stack>
                    <Typography
                      mt={2}
                      fontSize={14}
                      fontWeight={400}
                      color="#ffffff4d"
                    >
                      <ReactCountDown
                        flag={true}
                        endTime={this.state.endTime}
                      />
                    </Typography>
                  </Stack>
                </Stack>
              </DashboardWrapper>
              <DashboardWrapper>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={withdrawnIcon}
                      style={{ width: "15px", height: "15px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Withdrawn
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {formatEthFromWei(this.state.myCurWithdraw, 6)} BNB
                    </Typography>
                    <Typography fontSize={18} fontWeight={400} color="white">
                      ($
                      {formatEthFromWei(
                        (
                          Number(this.state.myCurWithdraw) *
                          Number(this.state.bnbValue)
                        ).toString(),
                        6
                      )}
                      )
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={totalRoiIcon}
                      style={{ width: "25px", height: "25px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Total ROI
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {formatNumber(this.state.totalReturnOfInvestment, 6)} %
                    </Typography>
                    {/* <Typography fontSize={18} fontWeight={400} color="white">
                      ($
                      {formatNumber(
                        (
                          Number(this.state.totalReturnOfInvestment) *
                          Number(this.state.bnbValue)
                        ).toString(),
                        6
                      )}
                      )
                        </Typography> */}
                  </Stack>
                </Stack>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={airdropIcon}
                      style={{ width: "16px", height: "16px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Airdrops
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {formatEthFromWei(this.state.myAirdrops, 6)} BNB
                    </Typography>
                    {/* <Typography fontSize={18} fontWeight={400} color="white">
                      ($
                      {formatEthFromWei(
                        (
                          Number(this.state.myAirdrops) *
                          Number(this.state.bnbValue)
                        ).toString(),
                        6
                      )}
                      )
                        </Typography> */}
                  </Stack>
                </Stack>
              </DashboardWrapper>
              <DashboardWrapper>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={clockIcon}
                      style={{ width: "14px", height: "14px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Next Action
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {this.state.nextIncomeCountdown}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className="content-stack">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={clockIcon}
                      style={{ width: "14px", height: "14px" }}
                      alt="deposit-icon"
                    />
                    <Typography fontFamily="Gilroy" fontSize={24} color="white">
                      Cut Off Timer
                    </Typography>
                  </Stack>
                  <Stack className="price-stack">
                    <Typography fontSize={18} fontWeight={500} color="#F8A21B">
                      {/* {new Date(
                        parseInt(this.state.cutoff) * 1000
                      ).toLocaleString()} */}
                      <ReactCountDown
                        flag={false}
                        endTime={this.state.cutoff}
                      />
                    </Typography>
                  </Stack>
                </Stack>
              </DashboardWrapper>
              <Box sx={{ display: "flex", mt: 4.5 }}>
                <Stack
                  className="opacity-stack-row"
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box className="border-box">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography fontSize={16} fontWeight={400} color="white">
                        Your 5x Status
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#F8A21B"
                      >
                        {formatEthFromWei(
                          (
                            (parseFloat(this.state.myCurDeposit) *
                              parseFloat(this.state.maxMultiplier)) /
                            100
                          ).toString(),
                          6
                        )}{" "}
                        BNB
                      </Typography>
                    </Stack>
                  </Box>
                  <Box className="border-box">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography fontSize={16} fontWeight={400} color="white">
                        Max Deposits
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#F8A21B"
                      >
                        {formatEthFromWei(this.state.maxDeposit, 6)} BNB
                      </Typography>
                    </Stack>
                  </Box>
                  <Box className="border-box">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography fontSize={16} fontWeight={400} color="white">
                        Real Deposits
                      </Typography>
                      <Typography
                        fontSize={16}
                        fontWeight={500}
                        color="#F8A21B"
                      >
                        {formatEthFromWei(
                          this.state.totalAmountOfDepositedBNB,
                          6
                        )}{" "}
                        BNB
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Typography
                className="deposit-description"
                fontSize={16}
                fontWeight={400}
                color="#ffffff4d"
              >
                The total amount in “MY DEPOSITS” cannot be more than 5 times
                the amount of real deposits of BNB. If you hit the 5x limit, you
                must deposit more BNB if you wish to continue reinvesting or
                receiving airdrops, referral benefits, etc.
              </Typography>

              {/* <Stack
                mt={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "600px",
                  "@media (max-width:767px)": {
                    flexDirection: "column",
                    width: "100%",
                  },
                }}
              >
                <Button
                  disabled={
                    this.state.totalAmountOfDepositedBNB === "0" ||
                    this.state.totalAmountOfDepositedBNB === ""
                  }
                  sx={{ color: "#F8A21B" }}
                  onClick={this.copyRefToClipboard}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ContentCopyIcon fontSize="small" />
                    <Typography fontSize={14}>Copy Referral Link</Typography>
                  </Stack>
                </Button>
                <SettingsDlg
                  totalAmountOfDepositedBNB={
                    this.state.totalAmountOfDepositedBNB
                  }
                  nickname={this.state.nickname}
                ></SettingsDlg>
              </Stack> */}

              <DepositWrapper>
                <Typography
                  className="m-title"
                  fontFamily="Gilroy"
                  fontSize={46}
                  color="white"
                >
                  Make A Deposit
                </Typography>
                <InputBase
                  className="sponsor-input"
                  type="text"
                  placeholder="Your Sponsor"
                  inputRef={this.sponsorInput}
                  disabled={
                    this.state.referredBy.length > 1 &&
                    this.state.referredBy !== "Will show after deposit"
                  }
                  value={this.state.sponsorAddress}
                  onChange={(event) =>
                    this.setState({ sponsorAddress: event.target.value })
                  }
                />
                <Stack
                  className="amount-input-stack"
                  direction="row"
                  alignItems="center"
                >
                  <Box className="amount-input-box">
                    <Stack direction="row" alignItems="center">
                      <InputBase
                        className="amount-input"
                        type="number"
                        placeholder="Amount In BNB"
                        inputRef={this.amountInput}
                        inputProps={{ min: 0.1, max: 25 }}
                        defaultValue={0.1}
                      />
                      <Stack
                        className="arrow-stack"
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <svg
                          width={17}
                          height={9}
                          viewBox="0 0 17 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity={0.3}
                            d="M0 9L8.5 9.53674e-07L17 9L0 9Z"
                            fill="white"
                          />
                        </svg>
                        <svg
                          width={17}
                          height={9}
                          viewBox="0 0 17 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 0L8.5 9L0 -1.48619e-06L17 0Z"
                            fill="white"
                          />
                        </svg>
                      </Stack>
                    </Stack>
                  </Box>
                  <Stack className="your-balance-stack" alignItems="flex-end">
                    <Typography
                      className="title"
                      fontSize={16}
                      fontWeight={500}
                      color="white"
                    >
                      Your Balance
                    </Typography>
                    <Typography
                      className="amount"
                      fontSize={16}
                      fontWeight={500}
                      color="#F8A21B"
                    >
                      {formatEthFromWei(this.state.balance, 6)} BNB
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  sx={{
                    width: 600,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "@media (max-width:767px)": {
                      width: "100%",
                      flexDirection: "column",
                      gap: 2,
                    },
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      className="deposit-button"
                      variant="contained"
                      disableRipple
                      disabled={window.connected !== true}
                      onClick={this.onClick_Invest}
                    >
                      Deposit
                    </Button>
                    <ConnectButton nickname={this.state.nickname} />
                  </Stack>
                  <Button
                    disabled={
                      this.state.totalAmountOfDepositedBNB === "0" ||
                      this.state.totalAmountOfDepositedBNB === ""
                    }
                    sx={{ color: "#F8A21B" }}
                    onClick={this.copyRefToClipboard}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <ContentCopyIcon fontSize="small" />
                      <Typography fontSize={14}>Copy Referral Link</Typography>
                    </Stack>
                  </Button>
                </Stack>
              </DepositWrapper>
            </Box>
            <Box className="statistics">
              <Stack spacing={1}>
                <Typography
                  className="description"
                  fontSize={18}
                  fontWeight={400}
                  color="white"
                  textAlign="center"
                >
                  BNB ALL-Stars is a high reward contract on binance smart chain
                  that is paying out 1.5% daily return on investment up to 365%.
                  Investors can compound and extend their earnings through
                  deposits, and reinvestments rewards as well as through team
                  based referrals.
                </Typography>
                <Typography
                  className="description"
                  fontSize={18}
                  fontWeight={400}
                  color="white"
                  textAlign="center"
                >
                  The principal deposit cannot be withdrawn, only the returns
                  and referral rewards are paid out.Using Smart Contracts,
                  Tokens, and Cryptocurrency in general is always a risk. DYOR
                  before investing.
                </Typography>
              </Stack>
              <StatisticsBoxWrapper>
                <div>
                  <Box className="main-grid">
                    <Box className="f-column">
                      <Box className="content">
                        <Typography
                          fontFamily="Gilroy"
                          fontSize={24}
                          color="white"
                        >
                          Personal Statistics
                        </Typography>
                        <Stack mt={2} spacing={0.5}>
                          <Stack
                            className="text-stack"
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text title"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Next Income(1.5%)
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {this.state.nextIncomeCountdown}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Estimated 1.5% Daily Earnings
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatNumber(this.state.dailyIncomeExpected, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Accumulated Income
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.dailyIncome, 6)} BNB
                              available
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Direct Referral Income
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(
                                this.state.directReferralIncome,
                                6
                              )}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Matching Bonus
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.matchingBonus, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Your Address
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {this.state.yourAddress}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Referred By
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {this.state.referredBy}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={16}
                              fontWeight={500}
                              color="#F8A21B"
                            >
                              User totals
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total Amount Of Deposited BNB
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(
                                this.state.totalAmountOfDepositedBNB,
                                6
                              )}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total Amount Of Received BNB
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(
                                this.state.totalAmountOfReceivedBNB,
                                6
                              )}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Direct Bonus Withdrawn
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.directBonusW, 6)} BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Bonus Reinvested (Direct/Match)
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.bonusReinvested, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Match Bonus Withdrawn
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.matchBonusW, 6)} BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Income Reinvested
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.incomeReinvested, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Reinvested Gross
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.reinvestedGross, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                    <Box className="f-column">
                      <Box className="content">
                        <Typography
                          fontFamily="Gilroy"
                          fontSize={24}
                          color="white"
                        >
                          Smart Contract Statistics
                        </Typography>
                        <Stack mt={2} spacing={0.5}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total Members
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {this.state.totalUsers}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Contract Balance
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.contractBalance, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total BNB Deposits All Time
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.totalDeposits, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total Withdrawn By Members
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(
                                this.state.withdrawnByMembers,
                                6
                              )}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total Reinvested By Members
                            </Typography>
                            <Typography
                              className="truncate-text"
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(this.state.totalReinvested, 6)}{" "}
                              BNB
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={16}
                              fontWeight={500}
                              color="#F8A21B"
                            >
                              Organization
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Personal Invited Partners
                            </Typography>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {this.state.personalInvitedPartners}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              Total Number Of Partners
                            </Typography>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {this.state.totalNumberOfPartners}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color="#ffffffb3"
                            >
                              All downline’s investments
                            </Typography>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              color="#ff9924b3"
                            >
                              {formatEthFromWei(
                                this.state.allDownlinesInvestments,
                                6
                              )}{" "}
                              BNB
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="main-grid"
                    sx={{ borderTop: "1px solid #ffffff4d" }}
                  >
                    <Box className="f-column">
                      <Box
                        sx={{
                          p: "30px",
                          "@media (max-width:767px)": {
                            p: "10px",
                          },
                        }}
                      >
                        <TeamView
                          defaultname={"My Referral Team"}
                          teamId={this.state.referral_team}
                        />
                      </Box>
                    </Box>
                    <Box className="f-column">
                      <Box
                        sx={{
                          p: "30px",
                          "@media (max-width:767px)": {
                            p: "10px",
                          },
                        }}
                      >
                        <TeamView
                          defaultname={"My Upline Team"}
                          teamId={this.state.upline_team}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              </StatisticsBoxWrapper>
            </Box>

            <Box className="airdrop">
              <Box className="content">
                <Box className="grid-item">
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#ffffff0d",
                      backdropFilter: "blur(25px)",
                      borderRadius: "15px",
                    }}
                  >
                    <Box py={3.5} px={3}>
                      <AirdropPanel balance={this.state.balance} />
                    </Box>
                  </Box>
                </Box>
                <Box className="grid-item">
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#ffffff0d",
                      backdropFilter: "blur(25px)",
                      borderRadius: "15px",
                    }}
                  >
                    <Box py={3.5} px={2}>
                      <Typography
                        fontFamily="Gilroy"
                        fontSize={24}
                        color="white"
                      >
                        Airdrop Statistics
                      </Typography>
                      <Stack mt={2} spacing={0.5}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="white"
                          >
                            Last Airdrop Sent
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="#FF9924"
                          >
                            {this.state.myLastAirdropSent}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="white"
                          >
                            Total Airdrops Sent
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="#FF9924"
                          >
                            {formatEthFromWei(this.state.myAirdrops, 6)} BNB
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="white"
                          >
                            Airdrops Available
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="#FF9924"
                          >
                            0 BNB
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="white"
                          >
                            Total Airdrops Received
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="#FF9924"
                          >
                            {/* {formatEthFromWei(this.state.airdropsW, 6)} BNB */}
                            {formatEthFromWei(
                              this.state.myTotalReceivedAirdrops,
                              6
                            )}{" "}
                            BNB
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="white"
                          >
                            Contracts Total Airdrops
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color="#FF9924"
                          >
                            {formatEthFromWei(this.state.totalAirdrops, 6)} BNB
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </MainWrapper>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            "& img": {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <img src={mainBg} alt="background" />
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: "36%",
              width: "500px",
              height: "500px",
              background: "#F8A21B",
              opacity: 0.15,
              filter: "blur(135.5px)",
            }}
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            "& img": {
              width: "auto",
              height: "auto",
            },
          }}
        >
          <img src={mediumWheel} alt="wheel-bg" />
        </Box>
        <Box className="height-bg" />
      </Box>
    );
  };
}

export default App;
