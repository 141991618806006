import React from 'react';
import gif1 from "../assets/img/orientier.gif";
import gif2 from "../assets/img/01.gif";
import gif3 from "../assets/img/cherry.gif";
import styled from '@emotion/styled';
import Img1 from "../assets/img/RollerCoasters.png";
// Define the props for the Banner component
interface BannerProps {
  imageUrl: string;
  linkUrl: string;
  altText: string;
}
// Creating a styled component for the container div
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap; 
`;

const Banner: React.FC<BannerProps> = ({ imageUrl, linkUrl, altText }) => {
  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer" style={{ margin: '0 8px' }}>
      <img src={imageUrl} alt={altText} style={{ width: '270px', height: '100px', borderRadius: '8px' }} />
    </a>
  );
}

// Main Advertisements component
const Advertisements: React.FC = () => {
  return (
    <Container>
  <Banner imageUrl={gif3} linkUrl="https://thecherryfarmer.com/?ref=0xf85464cd3457dc7935bc3e7dcfc03029efd4a292" altText="Cherry" />
           <Banner imageUrl={Img1} linkUrl="https://rollercoasterminer.com?ref=0x665081bdadc5d389d96ea03885982f3e675be964" altText="RollerCoasters" />
  <Banner imageUrl={gif1} linkUrl="https://orientier.one/project/bnb-allstars/" altText="Dapp Banner orientier" />
     {/*     <Banner imageUrl={gif2} linkUrl="https://www.goldenbeans.online/" altText="Dapp Banner GoldenBeans" /> */}
  {/*   <Banner imageUrl="https://via.placeholder.com/240x120" linkUrl="https://yourdapp.com" altText="Dapp Banner 4" /> */}
    </Container>
  );
}

export default Advertisements;
