import { styled, Stack } from "@mui/material";

export const MainWrapper = styled("div")({
  position: "absolute",
  width: "100%",
  marginTop: "129px",
  zIndex: 1001,
  "& .dashboard": {
    flex: 1,
    marginTop: "19px",
    paddingLeft: "24px",
    paddingRight: "24px",
    "& .opacity-stack-row": {
      padding: "10px 0",
      background: "#ffffff08",
      borderRadius: "5px",
      "& .border-box": {
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRight: "1px solid #ffffff1a",
      },
      "& .border-box:last-child": {
        borderRight: "none",
      },
    },
    "& .deposit-description": {
      marginTop: "24px",
      width: 600,
    },
  },
  "& .statistics": {
    marginTop: "182px",
    padding: "0 24px",
  },
  "& .airdrop": {
    marginTop: "182px",
    padding: "0 24px",
    "& .content": {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gap: "16px",
      "& .grid-item": {
        gridColumn: "span 6",
        height: "100%",
      },
    },
  },
  "& .faq": {
    marginTop: "127px",
    padding: "0 24px",
  },
  "@media (max-width:767px)": {
    marginTop: "96px",
    "& .dashboard": {
      marginTop: "50px",
      padding: 0,
      "& .opacity-stack-row": {
        flexDirection: "column",
        width: "100%",
        "& .border-box": {
          borderRight: "none",
          "& p": {
            fontSize: 14,
          },
        },
      },
      "& .deposit-description": {
        width: "100%",
        fontSize: 14,
      },
    },
    "& .statistics": {
      padding: 0,
      marginTop: "50px",
      "& .description": {
        fontSize: 14,
      },
    },
    "& .airdrop": {
      padding: 0,
      marginTop: "50px",
      "& .content": {
        gridTemplateColumns: "none",
        "& .grid-item": {
          gridColumn: "span 1",
          height: "100%",
        },
      },
    },
    "& .faq": {
      marginTop: "50px",
      padding: 0,
      "& .team-desc": {
        padding: "0 8px",
        "& p": {
          fontSize: 14,
        },
      },
      "& .guide": {
        "& p": {
          fontSize: 14,
        },
      },
      "& .how-to-start": {
        "& p": {
          fontSize: 14,
        },
      },
      "& .withdrawl-taxes": {
        "& p": {
          fontSize: 14,
        },
      },
      "& .important-text": {
        "& p": {
          fontSize: 14,
        },
      },
    },
  },
});

export const DashboardWrapper = styled(Stack)({
  marginTop: "24px",
  flexDirection: "row",
  alignItems: "flex-start",
  "& .content-stack": {
    minWidth: 245,
    gap: "20px",
    "& .price-stack": {
      gap: "4px",
    },
  },
  "@media (max-width:767px)": {
    flexDirection: "column",
    gap: "30px",
    "& .content-stack": {
      gap: "4px",
      "& .price-stack": {
        gap: 0,
      },
    },
  },
});

export const DepositWrapper = styled("div")({
  marginTop: "24px",
  "& .sponsor-input": {
    marginTop: "24px",
    padding: "20px 30px",
    width: "450px",
    border: "1px solid #ffffff4d",
    borderRadius: "7px",

    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    color: "white",
    "& .MuiInputBase-input": {
      p: 0,
    },
  },
  "& .amount-input-stack": {
    marginTop: "20px",
    gap: "48px",
    "& .amount-input-box": {
      padding: "20px 30px",
      width: "395px",
      border: "1px solid #ffffff4d",
      borderRadius: "7px",
      "& .amount-input": {
        width: "100%",
        height: "100%",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        color: "white",
        "& .MuiInputBase-input": {
          padding: 0,
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none",
          },
        },
      },
      "& .arrow-stack": {
        "& svg": {
          cursor: "pointer",
          "& path": {
            opacity: 0.3,
            fill: "white",
          },
          "&:hover": {
            "& path": {
              opacity: 1,
            },
          },
        },
      },
    },
    "& .your-balance-stack": {
      gap: "16px",
    },
  },
  "& .deposit-button": {
    padding: "8px 23px",
    backgroundColor: "#F8A21B",
    borderRadius: "55px",
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "#24203B",
    "&:hover": {
      backgroundColor: "rgb(36 32 59)",
      color: "white",
    },
    "@media (max-width:767px)": {
      fontSize: "14px",
    },
  },
  "@media (max-width:767px)": {
    "& .m-title": {
      fontSize: 40,
    },
    "& .sponsor-input": {
      marginTop: "16px",
      width: "100%",
    },
    "& .amount-input-stack": {
      marginTop: "16px",
      justifyContent: "space-between",
      gap: "12px",
      "& .amount-input-box": {
        width: "50%",
      },
      "& .your-balance-stack": {
        gap: "4px",
        "& .title": {
          fontSize: 12,
        },
        "& .amount": {
          fontSize: 12,
        },
      },
    },
  },
});

export const StatisticsBoxWrapper = styled("div")({
  marginTop: "74px",
  width: "100%",
  background: "#ffffff0d",
  backdropFilter: "blur(25px)",
  borderRadius: "15px",
  "& .main-grid": {
    display: "grid !important",
    gridTemplateColumns: "repeat(12, 1fr)",
    height: "100%",
    "& .f-column": {
      gridColumn: "span 6",
      height: "100%",
      borderRight: "1px solid #ffffff4d",
      "& .content": {
        padding: "30px",
        "& .truncate-text:nth-of-type(odd)": {
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& .truncate-text:nth-of-type(even)": {
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
    "& .f-column:last-child": {
      borderRight: "none",
    },
    "& .s-column": {
      gridColumn: "span 7",
    },
  },
  "@media (max-width:767px)": {
    "& .main-grid": {
      gridTemplateColumns: "none",
      "& .f-column": {
        gridColumn: "span 1",
        borderRight: "none",
        borderBottom: "1px solid #ffffff4d",
        "& .content": {
          padding: "10px",
          "& .truncate-text:nth-of-type(odd)": {
            width: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .truncate-text:nth-of-type(even)": {
            width: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
      "& .s-column": {
        gridColumn: "span 1",
        "& .button-text": {
          fontSize: 11,
        },
      },
    },
  },
});

export const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1301,
  width: "100%",
});
