import React from "react";
import Countdown from "react-countdown";

interface ReactCountDownProps {
  flag: boolean;
  endTime: number;
}

class ReactCountDown extends React.Component<ReactCountDownProps> {
  shouldComponentUpdate(
    nextProps: Readonly<ReactCountDownProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (nextProps.endTime === this.props.endTime) {
      return false; // Prevent re-render if parent state hasn't changed
    }
    return true;
  }

  state = {
    endTime: 1,
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ReactCountDownProps) {
    super(props);

    this.setState({
      endTime: props.endTime,
    });
  }

  handleComplete = async () => {
    const _now = Date.now().valueOf();
    console.log(_now);
  };

  renderer = (props: any, flag: boolean) => {
    const { completed } = props;
    // console.log("props: ", props)

    const hours = Number(props.formatted.hours);
    const minutes = Number(props.formatted.minutes);
    const seconds = Number(props.formatted.seconds);

    // const timeText = `
    //         ${days} days,
    //         ${hours} hours,
    //         ${minutes} minutes,
    //         ${seconds} seconds
    //     `;
    const timeText = `
            ${hours ? (hours < 10 ? `0${hours}:` : `${hours}:`) : ""}
            ${minutes ? (minutes < 10 ? `0${minutes}:` : `${minutes}:`) : "00:"}
            ${seconds ? (seconds < 10 ? `0${seconds}` : `${seconds}`) : "00"}`;
    return (
      <span className="font-bold text-2xl">
        {flag ? (
          <>
            {completed
              ? "00:00 until your next action"
              : `${timeText} until your next action`}
          </>
        ) : (
          <>{completed ? "00:00" : timeText}</>
        )}
      </span>
    );
  };
  render = () => {
    return (
      <>
        {this.props.endTime > 1 ? (
          <Countdown
            daysInHours={true}
            autoStart={true}
            date={this.props.endTime}
            renderer={(e) => this.renderer(e, this.props.flag)}
            onComplete={this.handleComplete}
          />
        ) : (
          <span className="font-bold text-2xl">00:00</span>
        )}
      </>
    );
  };
}

export default ReactCountDown;
